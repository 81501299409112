.pm-heading h5 {
    color: #474848;
    font-size: 50px;
    font-weight: 600;
    line-height: 66px;
    letter-spacing: -1.8px;
}

.pm-heading h6 {
    color: #8F9193;
    font-size: 15px;
    font-style: normal;
}

.pm-heading1 .card {
    width: 250px;
    height: 400px;
    border-radius: 13px;
    cursor: pointer;
}

.pm-heading1 .card-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #F1F1F1;
    border-radius: 10px;
}

.pm-heading1 .card img {
    width: 100%;
    border-radius: 15px;
}

.pm-heading1 .card span {
    color: #8B8B8B;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.popular-menu-svg {
    width: 40vw;
}

.popular-menu-image {
    position: absolute;
    bottom: 12vw;
    background: white;
    padding: 10px;
    border-radius: 100%;
    height: 20vw;
    width: 20vw;
    left: 12vw;
}

.popular-menu-heading {
    position: absolute;
    color: white;
    z-index: 99;
    top: 18vw;
    right: 8vw;
    font-size: 30px;
    font-weight: bolder;
}

.popular-menu-btn {
    right: 10vw;
    background-color: white;
    color: #e75605;
    width: 171px;
    height: 37px;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px;
    position: absolute;
    bottom: 5vw;
    font-weight: 600;
}

.popovericon {
    font-size: 18px;
    position: relative;
    cursor: pointer;
    padding-left: 4px;
    margin: 10px 0;
    transform: translateY(-50%);
}

.twopm-text {
    text-align: center;
    font-size: 19px;
    font-weight: 400;
    color: #e66616;
}


@media screen and (max-width: 600px) {
    .pm-heading h5 {
        font-size: 30px;
    }

    .pm-heading h6 {
        font-size: 10px;
    }

    .pm-heading1 h6 {
        font-size: 10px;
        white-space: break-spaces;
    }
}

.u-clearfix:before,
.u-clearfix:after {
    content: " ";
    display: table;
}

.u-clearfix:after {
    clear: both;
}

.u-clearfix {
    zoom: 1;
}

.pm-subtle {
    color: #aaa;
}

.pm-card-container {
    margin: 0 auto 0;
    position: relative;
}

.pm-card {
    background-color: #fff;
    padding: 25px;
    position: relative;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    z-index: 1;
    margin-top: 15px;
    margin-bottom: 1px;
    border-radius: 5px;
}

.pm-card-number {
    margin-top: 15px;
}

.pm-card-circle {
    border: 1px solid #aaa;
    border-radius: 50%;
    display: inline-block;
    line-height: 22px;
    font-size: 12px;
    height: 25px;
    text-align: center;
    width: 25px;
    text-overflow: ellipsis;
}

.pm-card-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pm-card-title {
    /* font-family: 'Cormorant Garamond', serif; */
    font-size: 20px;
    font-weight: 400;
    text-overflow: ellipsis;
    line-height: 35px;
}

.pm-card-description {
    /* display: flex; */
    font-weight: 100;
    line-height: 24px;
    width: 220px;
    text-align: justify;
    margin: 10px 0;
    height: 100px;
    /* text-overflow: ; */
    overflow-y: auto;
}

.menu-items {
    padding: 10px;
    font-size: 15px;
    margin-right: 10px;
    margin-bottom: 15px;
}


.pm-card-description .badge {
    margin-right: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
}

.pm-card-read {
    text-align: center;
    cursor: pointer;
    background-color: #e75605;
    color: white;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px;
    bottom: 5vw;
    font-weight: 600;
    font-size: large;
    height: 35px;
}

.pm-card-tag {
    float: right;
    margin: 5px 0 0;
}

.pm-card-media {
    float: right;
    margin-top: 4%;
    border-radius: 10px;
    width: 100px;
    height: 100px;
}

.pm-card-shadow {
    background-color: #fff;
    box-shadow: 0 2px 25px 2px rgba(0, 0, 0, 1), 0 2px 50px 2px rgba(0, 0, 0, 1), 0 0 100px 3px rgba(0, 0, 0, .25);
    height: 1px;
    margin: -1px auto 0;
    width: 80%;
    z-index: -1;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.pm-card-container {
    display: flex;
    height: auto;
    flex-direction: column;
}

.button-container {
    /* text-align: center; */
    margin: 10px 0;
}

.card-closed {
    background-color: #fff;
    padding: 30px;
    position: relative;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    z-index: 1;
    margin-top: 1px;
    margin-bottom: 1px;
    border-radius: 5px;
    height: 160px;
}

.closed-img {
    height: 60%;
}

.closed-text {
    /* font-family: 'Cormorant Garamond', serif; */
    font-size: 20px;
    font-weight: 300;
}

.day-badge {
    float: left;
    margin-left: '15px'
}

.popover-tip {
    border: 1px solid #c3bcbc;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    background-color: #f9f9f9;
    padding: 11px;
    width: 250px;
    margin: 8px;
    text-align: justify;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

@media (max-width: 1440px) {
    .pm-card-description {
        width: 240px;
    }
}

@media (max-width: 1024px) {
    .pm-card-description {
        width: 250px;
    }
}

@media (max-width: 768px) {
    .pm-card {
        margin-bottom: 5px;
    }

    .day-badge{
        float:left;
        margin-left: 0 !important;
    }

    .pm-card-description {
        font-weight: 100;
        line-height: 30px;
        width: 440px;
        text-align: justify;
        margin: 10px 0;
        height: 100px;
        overflow-y: auto;
    }

    .flex-container {
        justify-content: flex-start;
    }

    .pm-card-media {
        margin-top: -6px;
        width: 150px;
        height: 150px;
    }

    .closed-text {
        font-size: 15px;
        font-weight: 300;
    }
}

@media (max-width: 475px) {
    .flex-container {
        flex-direction: column;
    }

    .pm-card-title {
        font-size: 30px;
        white-space: normal;
        font-weight: 300;
        line-height: 30px;
    }

    .popovericon {
        font-size: 13px;
        margin: 3px 0;
    }


    .pm-card-media {
        width: 100px;
        height: 100px;
        margin-top: 10px;
        margin-left: 15px;
        float: left;
    }
}

.cutoff-badge {
    background-color: #E75605 !important;
    font-size: "15px";
    margin-right: "10px";
    margin-bottom: "15px";
    padding: "10px";
}

.pm-card-description::-webkit-scrollbar {
    width: 5px;
}

.pm-card-description::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.pm-card-description::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.pm-card-description::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.text-wrap-balance {
    text-wrap: balance;
}

.pm-card-names {
    font-weight: 100;
    line-height: 24px;
    /* width: 210px;
    text-align: justify; */
    margin: 10px 0;
    overflow-y: auto;
}

.pm-card-universityname {
    font-weight: 700;
    line-height: 24px;
    width: 220px;
    /* text-align: justify; */
    margin: 10px 0;
    overflow-y: auto;
    text-wrap: balance;
}

.pm-card-location {
    /* font-family: 'Cormorant Garamond', serif; */
    /* font-size: 20px; */
    font-weight: 500;
    text-overflow: ellipsis;
    line-height: 24px;
}

.menuimg {
    width: 100%;
    height: 275px;
}