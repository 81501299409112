.border-container {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
}

.ch-default-img {
  margin-top: -6px;
  border-radius: 10px;
  width: 70px;
  height: 65px;
  margin-bottom: 4px;
  margin-left: -10px;
}

.ch-img {
  /* width: 60px !important;
    height: 60px !important;
    display: block;
    margin: -8px 12px 22px -2px;
    padding: 5px;
    border-radius: 15px; */
  /* float: left; */
  margin-top: -6px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.cancel-button {
  width: 171px;
  height: 37px;
  border-radius: 5px;
  border: 1px;
  padding: 1px 11px 1px 11px
}

.submit-button {
  width: 171px;
  height: 37px;
  border-radius: 5px;
  border: 1px;
  padding: 1px 11px 1px 11px;
  background: #e75605;
  color: white;
}


@media screen and (max-width: 2560px) {
  .submit-button {
    width: 200px;
    height: 40px;
  }

  .cancel-button {
    width: 200px;
    height: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .submit-button {
    width: 180px;
    height: 35px;
  }

  .cancel-button {
    width: 190px;
    height: 35px;
  }
}

@media screen and (max-width: 1024px) {
  .submit-button {
    width: 150px;
    height: 35px;
  }

  .cancel-button {
    width: 150px;
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  .submit-button {
    width: 125px;
    height: 35px;
  }

  .cancel-button {
    width: 125px;
    height: 35px;
  }

  .display-time{
    display: flex;
  }
}

@media screen and (max-width: 475px) {
  .submit-button {
    width: 140px;
    height: 35px;
  }

  .cancel-button {
    width: 150px;
    height: 35px;
  }
}