.input-grp-width {
  width: 130px
}

.label-input {
  margin-right: 10px !important;
  white-space: nowrap !important
}

.custom-small-input {
  font-size: 14px !important;
  padding: 4px !important;
}

.loginbtn {
  background-color: #E75605 !important;
  height: 35px;
  border: none;
  border-style: none;
  color: white;
  border-radius: 4px;
}

.custom-border {
  border: 1px solid #004e73 !important;
  border-radius: 10px;
}

.custom-active-tab {
  background-color: #3a8ea942 !important;
  color: #004e73 !important;
  border: 1px solid #004e73 !important;
  border-radius: 5px;
}

.margin-left-row {
  margin-left: 20px !important;
}

.additembtn {
  background-color: white;
  height: 35px;
  border: none;
  border-style: none;
  color: #004e73;
  border-radius: 4px;
  margin-top: 50px;
  margin-right: 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: caret;
  margin: 0;
}

.custom-image-upload{
  margin-bottom: -60px;
}