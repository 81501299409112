.tickets-page input[type=radio] {
    display: none;
}

.tickets-page input[type=radio]:checked+.item .ticket-title {
    color: #fff;
    border-color: #555555;
    background-color: #555555;
}

.tickets-page input[type=radio]:checked+.item .ticket-title:after {
    transform: rotate(180deg);
}

.tickets-page input[type=radio]:checked+.item .ticket-content {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    height: auto !important;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s, opacity 0.7s;
}

.tickets-page .item {
    display: block;
    margin-bottom: 0rem;
}

.custom-ticket-height {
    height: 55px;
}

.tickets-page .ticket-title {
    padding: 0.1rem 1rem;
    border-radius: 0.3rem;
    color: #555555;
    border: 1px solid;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 1rem;
    height: 50px;
}

.tickets-page .ticket-title:after {
    content: "▼";
    display: inline-block;
    font-size: 12px;
}

.tickets-page .ticket-content {
    display: flex;
    width: 95%;
    margin: 0 auto;
    padding: 0.3rem 0.3rem;
    line-height: 1.6;
    color: #555555;
    border: 1px solid;
    border-width: 0 1px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    text-align: start;
}

.ticket-content textarea {
    width: 90%;
    border-radius: 5px;
}

.ticket-content select {
    width: 10%;
    border-radius: 5px;
    margin-left: 10px;
}

.tickets-page .ticket-content i {
    border: 1px solid;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.tickets-page .ticket-content p {
    font-size: 15px;
    padding-right: 60px;
    width: 90%;
}

.tickets-page .ticket-content .badge {
    height: fit-content;
    position: absolute;
    right: 4vw;
}

.tickets-page .ticket-content h6 {
    position: relative;
    right: 5vw;
    top: 1vw;
    white-space: nowrap;
}

.tickets-page .ticket-content h5 {
    font-size: 12px !important;
    font-weight: 600;
    color: #3a4a95;
}

.tickets-page .ticket-user-content i {
    padding: 8px;
    color: #287f5f;
}

.tickets-page .ticket-admin-content i {
    padding: 7px;
    color: #e75606;
}

.plane-i {
    margin-top: 5px;
    width: 60px;
    margin-left: 10px;
    cursor: pointer;
    padding: 8px 12px;
    border: 1px solid;
    border-radius: 5px;
    height: 40px;
    background: #e75606;
    color: white;
}

@media screen and (max-width: 1024px) {
    
    .tickets-page .ticket-content h6 {
        right: 6vw;
    }
}

@media screen and (max-width: 768px) {
    
    .tickets-page .ticket-content h6 {
        right: 11vw;
    }
}

@media screen and (max-width: 475px) {
    .tickets-page .ticket-content .badge {
        right: 7vw;
    }

    .tickets-page .ticket-title {
        font-size: 11px;
        height: 45px;
    }

    .user {
        margin-bottom: 1px;
    }

    .tickets-page .ticket-content p {
        font-size: 12px;
        width: 170px;
        text-align: justify;
        margin: 10px 0;
        height: 40px;
        overflow-y: auto;   
    }

    .tickets-page .ticket-content h6 {
        position: relative;
        right: 70px;
        top: 50px;
        font-size: 11px;
        white-space: nowrap;
    }
}