.contact-email{
    font-weight: 900;
    font-size: 24px;
    background: #ececec;
    padding: 2px;
    width: 370px;
    border-radius: 10px;
}

@media screen and (max-width :768px) {
    .contact-email{
        font-weight: 900;
        font-size: 24px;
        background: #ececec;
        width: 370px;
        border-radius: 10px;
    }
}


@media screen and (max-width :475px) {
    .contact-email{
        font-weight: 900;
        font-size: 20px;
        background: #ececec;
        width: 337px;
        border-radius: 10px;
    }
}