.banner {
    width: 100%;
    height: 450px;
    background-size: cover;
    background-image: linear-gradient(to bottom left, rgb(0 0 0 / 15%), black), url("../../assets//images/banner/banner.jpeg");
    background-position: center;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.banner-text {
    z-index: 0;
    width: 100%;
    left: 5%;
    top: 15%;
    position: relative;
    padding-left: 40px;
    padding-right: 30px;
}

.banner-strip {
    padding-top: 30px;
    height: 100px;
    z-index: 0;
    width: 80%;
    left: 7%;
    top: 30%;
    position: relative;
    padding-left: 40px;
    padding-right: 30px;
    border-radius: 40px;
    background: rgba(217, 217, 217, 0.20);
}

.banner-strip img {
    height: 40px;
}

.banner-strip p {
    color: white;
    margin-left: 20px;
    margin-top: 10px;
}

.banner-col {
    border-right: 1px solid white;
    justify-content: center;
    display: flex;
}

.banner-col:last-child {
    border-right: unset;
}

.banner-text p {
    color: #FFF;
    font-size: 26px;
    margin-bottom: 40px;
}

.banner-text h1 {
    color: #FFF;
    font-size: 60px;
    font-style: normal;
    line-height: 66px;
    letter-spacing: -1.8px;
}

.banner-text a {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 15px;
    border-radius: 0;
    background: #E75605;
    border: none;
    margin-top: 40px;
}

.pm-card-author {
    display: block;
    font-size: 23px;
    letter-spacing: .5px;
    margin: 50px 0 0;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .banner-text h1 {
        color: #FFF;
        font-size: 40px;
        font-style: normal;
        line-height: 66px;
        letter-spacing: -1.8px;
    }

    .banner-text p {
        color: #FFF;
        font-size: 18px;
    }

    .banner-strip {
        padding-top: 30px;
        height: 100px;
        z-index: 0;
        width: 90%;
        left: 7%;
        top: 30%;
        position: relative;
        padding-left: 40px;
        padding-right: 30px;
        border-radius: 40px;
        background: rgba(217, 217, 217, 0.20);
    }

}

@media screen and (max-width: 510px) {

    .banner-col {
        border-right: unset;
        justify-content: center;
        display: block;
    }

    .banner-col p {
        margin-left: -30px;
        text-align: center;
    }

    .banner-text h1 {
        color: #FFF;
        font-size: 35px;
        font-style: normal;
        line-height: 66px;
        letter-spacing: -1.8px;
    }

    .banner-text p {
        color: #FFF;
        font-size: 15px;
    }

    .banner-strip {
        padding-top: 10px;
        height: 100px;
        z-index: 0;
        width: 100%;
        left: 0%;
        top: 30%;
        position: relative;
        padding-left: 40px;
        padding-right: 30px;
        border-radius: 40px;
        background: rgba(217, 217, 217, 0.20);
    }

}