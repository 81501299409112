
.Ordernow {
    background-color: #E75605;
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
}

.left-section {
    margin-left: 100px;
}

.leftalign{
    margin-left: 10px;
}

.right-section{
    margin-bottom: 20px;
}

.header{
    margin-top: 60px;
}
