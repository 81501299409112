.res_name {
    color: #E75605;
    font-weight: 900;
    text-align: center;
    font-size: 18px;
}

.edit-res {
    margin-top: -35px;
    margin-right: 20px;
}

.time-picker-adjustment {
    /* margin-top: -75px; */
    margin-bottom: 35px;
}

.address-card {
    background-color: #f1f1f18a;
    border-radius: 20px;
    padding: 2px 10px 15px 10px;
}

.address-btn {
    margin-top: 10px;
    margin-bottom: 20px;
}

.less-btn {
    color: rgb(240, 13, 13);
    margin-right: -10px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 24px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 2px 4px #00000045;
}

.res-toggle {
    margin-top: 25px;
    margin-left: 100px;
    margin-right: 20px;
}

@media screen and (max-width: 768px) {
    .time-picker-adjustment {
        margin-top: -55px;
        margin-bottom: 22px;
    }

    .res-toggle {
        margin-top: 25px;
        margin-left: 525px;
        margin-right: 21px;
    }
}

@media screen and (max-width: 475px) {
    .time-picker-adjustment {
        margin-top: -55px;
        margin-bottom: 22px;
    }

    .address-btn {
        margin-top: -42px;
        margin-bottom: 20px;
    }

    .address-header {
        margin-bottom: -38px;
    }

    .res-toggle {
        margin-top: 25px;
        margin-left: 134px;
        margin-right: 21px;
    }
}