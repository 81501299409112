.payment-card {
    position: absolute !important;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 60% !important; */
    padding: 15px;
    z-index: 999;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}

/* .custom-StripeElement {
    padding: 10px !important;
    border: 1px solid !important;
    border-radius: 5px !important;
} */

.payment-btn {
    margin-left: 10px;
}