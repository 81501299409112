.status {
    font-size: 220;
    color: "#FDCC0D";
}

.linebar {
    height: 6px;
    background-color: #a7aeb1;
    width: 470px;
}

.linebar.active {
    border-top: 3px solid #E75605;
    background-color: #E75605;
}

.progress-bar-container {
    display: flex;
    justify-content: space-between;
}

.step {
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #fff;
}

.step.active {
    background-color: #E75605;
    color: #fff;
}

.line {
    border-top: 3px solid #E75605;
    width: 100%;
    margin-top: 10px;
}

.line.active {
    border-top: 3px solid #E75605;
    width: 100%;
    margin-top: 10px;
}

.progress-item {
    display: flex;
    align-items: center;
}

.maxbtn {
    border-top-right-radius: 40%;
    border-bottom-right-radius: 40%;
    overflow: hidden;
    background-color: #E75605;
    border: none
}

p {
    font-size: 14px;
    margin-bottom: 7px;
}

.cursor-pointer {
    cursor: pointer;
}

.bold {
    font-weight: 600;
}

.small {
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
}

.Today {
    color: rgb(83, 83, 83);
}

.btn-outline-primary {
    background-color: #fff !important;
    color: #4bb8a9 !important;
    border: 1.3px solid #4bb8a9;
    font-size: 12px;
    border-radius: 0.4em !important;
}

.btn-outline-primary:hover {
    background-color: #4bb8a9 !important;
    color: #fff !important;
    border: 1.3px solid #4bb8a9;
}

.btn-outline-primary:focus,
.btn-outline-primary:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #42A5F5 !important;
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px
}

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 33.33%;
    float: left;
    position: relative;
    font-weight: 400;
    color: #455A64 !important;

}

#progressbar #step1:before {
    content: "1";
    color: #fff;
    width: 29px;
    margin-left: 15px !important;
    padding-left: 11px !important;
}


#progressbar #step2:before {
    content: "2";
    color: #fff;
    width: 29px;

}

#progressbar #step3:before {
    content: "3";
    color: #fff;
    width: 29px;
    margin-right: 15px !important;
    padding-right: -5px !important;
}

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #455A64;
    border-radius: 50%;
    margin: auto;
}

#progressbar li:after {
    content: '';
    width: 121%;
    height: 2px;
    background: #455A64;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 15px;
    z-index: -1;
}

#progressbar li:nth-child(2):after {
    left: 50%;
}

#progressbar li:nth-child(1):after {
    left: 25%;
    width: 121%;
}

#progressbar li:nth-child(3):after {
    left: 25% !important;
    width: 50% !important;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #4bb8a9;
}

.card {
    background-color: #fff;
    box-shadow: 2px 4px 15px 0px rgb(0, 108, 170);
    z-index: 0;
}

small {
    font-size: 12px !important;
}

.a {
    justify-content: space-between !important;
}

.border-line {
    border-right: 1px solid rgb(226, 206, 226)
}

.card-footer img {
    opacity: 0.3;
}

.card-footer h5 {
    font-size: 1.1em;
    color: #8C9EFF;
    cursor: pointer;
}

.primary-color {
    color: #e75605 !important;
}

.process-orders {
    background-color: #E75605 !important;
    width: 171px;
    height: 37px;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px 1px 11px
}

.cancel-btn {
    width: 171px;
    height: 37px;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px 1px 11px
}

.oc-card-description {
    display: unset !important;
    font-weight: 300 !important;
    line-height: 22px !important;
    margin: 10px 0 !important;
}

.oc-card-description .badge {
    margin-right: 10px !important;
    margin-bottom: 5px;
    padding: 10px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.single_menu {
    position: relative;
    margin-bottom: 75px;
    transition: .3s;
}

/* .single_menu:hover img {
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);

} */

.single_menu img {
    width: 90%;
    position: absolute;
    height: 140px;
    /* -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%); */
    /* transition: .3s; */
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-left: 0px;
}

.menu_content {
    padding-left: 250px;
}

.menu_content h4 {
    font-size: 20px;
    font-weight: 300;
    border-bottom: 1px dashed #c0392b;
    line-height: 2;
    text-transform: capitalize;
}

.menu_content h4 span {
    font-size: 20px;
    font-weight: 800;
    float: right;
    font-style: italic;
    color: #c0392b;
}

.menu_content p {
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 1px;
}

.count {
    position: absolute;
    right: 5%;
    font-size: 15px;
    font-weight: 600;
    background-color: white;
}

.count i {
    margin: 0px 20px;
    cursor: pointer;
    background: #e75606;
    color: white;
    padding: 5px;
    font-size: 12px;
    border-radius: 2px;
}

.count .disabled-i {
    margin: 0px 20px;
    cursor: not-allowed;
    background: #ccc5c1 !important;
    color: white;
    padding: 5px;
    font-size: 12px;
    border-radius: 2px;
}

.myorder-card-description {
    font-weight: 100;
    line-height: 30px;
    width: 340px;
    text-align: justify;
    margin: 10px 0;
    height: 100px;
    overflow-y: auto;
}

.paymenttype .card {
    border-radius: 5px;
    width: fit-content !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20%;
    cursor: pointer;
}

.paymenttype img {
    width: 100%;
}

.cm-cancel-button {
    width: 171px;
    height: 37px;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px 1px 11px
}

.cm-submit-button {
    width: 171px;
    height: 37px;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px 1px 11px;
    background: #e75605 !important;
    color: white;
}

.body-card {
    display: 'flex';
    flex-direction: 'column';
}

.qr-code {
    position: "absolute";
    right: "1vw";
    top: -20px
}

.mo-heading {
    font-size: 25px;
}

.quantity-error {
    font-size: 14px;
    text-align: right;
    margin-top: 10px;
    color: #dc5454;
}

.cc-tip {
    border-bottom: 1px solid #c3bcbc;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    background-color: #f9f9f9;
    padding: 10px;
    width: 160px;
    margin: 0px;
    text-align: start;
}

.coupon-col {
    display: flex;
    margin-top: -2px;
    margin-bottom: 50px;
}

.TextField.coupon-input input {
    margin-right: 10px !important;
}

.coupon-btn {
    margin-right: 10px;
}

.avl-coupon {
    cursor: pointer;
    color: blue;
    font-size: 12px;
    font-weight: 600;
    padding: 8px;
}

.coupon-badge {
    white-space: nowrap;
    position: absolute;
    top: -34px;
    right: 150px;
    background: #277c04ed;
    color: #ffffff;
    border-radius: 4px;
    padding: 5px;
    font-size: 10px;
    font-weight: 500;
}

.dlt-icon {
    font-size: 20px;
    color: red;
    padding: 10px;
    cursor: pointer;
}

.valid-msg {
    position: absolute;
    top: 53px;
    font-weight: 500;
}

@media screen and (max-width: 2560px) {
    .cm-submit-button {
        width: 200px;
        height: 40px;
    }

    .cm-cancel-button {
        width: 200px;
        height: 40px;
    }
}

@media screen and (max-width: 1440px) {
    .cm-submit-button {
        width: 180px;
        height: 35px;
    }

    .cm-cancel-button {
        width: 175px;
        height: 35px;
    }
}

@media screen and (max-width: 1024px) {
    .cm-submit-button {
        width: 150px;
        height: 35px;
    }

    .cm-cancel-button {
        width: 150px;
        height: 35px;
    }

    .myorder-card-description {
        width: 280px;
    }

    .avl-coupon {
        cursor: pointer;
        color: blue;
        font-size: 12px;
        font-weight: 600;
        padding: 8px;
        white-space: nowrap;
    }


}

@media screen and (max-width: 768px) {
    .cm-submit-button {
        width: 125px;
        height: 35px;
    }

    .cm-cancel-button {
        width: 125px;
        height: 35px;
    }

    .single_menu img {
        margin-left: 210px;
        width: 37%;
        height: 120px;
    }

    .menu_content h4 {
        margin-top: 140px;
    }

    .count {
        margin-top: 9px;
    }

    .myorder-card-description {
        width: 370px;
    }

    .coupon-col {
        display: flex;
        margin-top: 39px;
        margin-bottom: 50px;
    }

    .coupon-btn {
        margin-right: 10px;
        height: 42px;
    }

    .avl-coupon {
        cursor: pointer;
        color: blue;
        font-size: 12px;
        padding: 12px;
        font-weight: 600;
        white-space: nowrap;
    }

    .coupon-badge {
        white-space: nowrap;
        position: absolute;
        top: -18px;
        right: 82px;
        background: #277c04ed;
        color: #ffffff;
        border-radius: 4px;
        padding: 5px;
        font-size: 10px;
        font-weight: 500;
    }
}

@media screen and (max-width: 475px) {
    .cm-submit-button {
        width: 140px;
        height: 35px;
    }

    .cm-cancel-button {
        width: 150px;
        height: 35px;
    }

    .single_menu img {
        height: 100px;
        margin-left: 70px;
        width: 55%;
    }

    .menu_content h4 {
        margin-top: 120px;
    }

    .count {
        right: 20%;
        margin-top: -22px;

    }

    .myorder-card-description {
        width: 270px;
    }

    .body-card {
        display: 'flex';
        flex-direction: 'column';
        margin-top: -25px;
    }

    .qr-code {
        margin-top: 10px;
        text-align: center;
    }

    .mo-text {
        font-size: 12px;
    }


    .coupon-col {
        margin-bottom: 90px;
        top: 17px;
    }

    .avl-coupon {
        cursor: pointer;
        color: blue;
        font-size: 11px;
        padding: 8px;
        font-weight: 600;
    }

    .cc-tip {
        border-bottom: 1px solid #c3bcbc;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        background-color: #f9f9f9;
        padding-bottom: 13px;
        width: 150px;
        margin-left: 10px;
        margin-top: -18px;
        text-align: start;
    }
}

@media screen and (max-width: 375px) {
    .avl-coupon {
        cursor: pointer;
        color: blue;
        font-size: 11px;
        padding: 1px;
        font-weight: 600;
        white-space: normal;
    }

    .coupon-badge {
        top: -24px;
        right: 73px;

    }
}

@media screen and (max-width: 320px) {
    .avl-coupon {
        cursor: pointer;
        color: blue;
        font-size: 11px;
        padding: 1px;
        font-weight: 600;
        white-space: normal;
    }

    .cc-tip {
        border-bottom: 1px solid #c3bcbc;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        background-color: #f9f9f9;
        padding-bottom: 5px;
        width: 103px;
        margin-top: -3px;
        /* margin-right: -6px; */
        margin-left: -25px;
        text-align: start;
    }

    .coupon-badge {
        top: -33px;
        right: 29px;

    }
}

.myorder-card-description::-webkit-scrollbar {
    width: 8px;
}

.myorder-card-description::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.myorder-card-description::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.myorder-card-description::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.sidebar-payment {
    height: 100%;
    border-right: 1px solid #dad5d5;
}

.nav-payment {
    position: relative;
    font-weight: bold;
}

.nav-payment ul {
    padding: 0 !important;
    list-style: none;
}

.nav-payment ul li {
    position: relative;
    margin: 1em 0;
    cursor: pointer;
}

.nav-payment ul li i {
    font-size: 12px;
}

.nav-payment ul li.active {
    color: #E75605;
    cursor: pointer;
}

.nav-payment ul li.inactive {
    color: #a9a39f;
    cursor: unset;
}

.payment-type img {
    margin: 0px auto;
    width: 75%;
    height: 100%;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
}

.menuimg {
    width: 100%;
    height: 275px;
}

.btnClr{
    background-color: #E75605 !important;
    color:white !important;
}

.btninactive{
    background-color: #adb8c1 !important;
    color: black !important;
}