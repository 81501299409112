.menu-items {
    background-color: #F1F1F1;
    font-size: 13px
}

.qr-code-adjustment {
    margin-top: -40px;
}

.my-order-btn {
    right: 0px;
    top: 0px;
    cursor: pointer;
    text-align: end;
}

.qr-code {
    position: "absolute";
    right: "1vw";
    top: -20px
}

.os-card-description {
    font-weight: 100;
    line-height: 30px;
    width: 265px;
    text-align: justify;
    margin: 10px 0;
    height: 100px;
    overflow-y: auto;
}

@media screen and (max-width: 1440px) {
    .qr-code-adjustment {
        margin-top: -2px;
    }
}

@media screen and (max-width: 1024px) {
    .qr-code-adjustment {
        margin-top: 5px;
    }
}

@media screen and (max-width: 768px) {
    .qr-code-adjustment {
        margin-top: 20px;
    }
}

@media screen and (max-width: 475px) {
    .qr-code-adjustment {
        margin-top: 20px;
    }

    .os-text {
        font-size: 12px;
    }

    .my-order-btn {
        position: relative;
        text-align: end;
        cursor: pointer;
    }
    
    .qr-code {
        margin-top: 10px;
        text-align: center;
    }
}