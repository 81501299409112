.footer-social-row span {
    background-color: white;
    padding: 10px;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: inline-block;
}

.footer-row h6 {
    color: black;
    font-weight: 600;
}

.footer-row img {
    width: 28%;
}

@media screen and (max-width: 768px) {
    .footer-row img {
        width: 30%;
        margin-top: 10px;
    }
}


@media screen and (max-width: 475px) {
    .footer-row img {
        width: 31%;
        margin-top: -10px;
    }
}

.footer-social-row span img {
    width: 30px;
}

.footer-row p {
    white-space: nowrap;
}

.footer-address {
    text-align: end;
}

.footer-logo {
    text-align: center;
}

.footer-time {
    text-align: start;
}