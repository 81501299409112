.border-container {
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.cancel-btn {
    width: 150px;
    height: 37px;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px 1px 11px
}

.search-btn {
    background-color: #E75605 !important;
    width: 150px;
    height: 37px;
    border-radius: 5px;
    border: 1px;
    padding: 1px 11px 1px 11px
}

.tw-toggle {
    /* background: #95A5A6; */
    display: inline-block;
    padding: 2px 3px;
    border-radius: 5px;
    position: relative;
    border: 1px solid #95A5A6;
}

.tw-toggle label {
    text-align: center;
    font-family: sans-serif;
    display: inline-block;
    color: #95A5A6;
    position: relative;
    z-index: 2;
    margin: 0;
    text-align: center;
    padding: 2px 3px;
    font-size: 15px;
    /* cursor: pointer; */
}

.tw-toggle input {
    /* display: none; */
    position: absolute;
    z-index: 3;
    opacity: 0;
    cursor: pointer;
}

.tw-toggle span {
    height: 21px;
    width: 21px;
    line-height: 21px;
    border-radius: 1%;
    background: #fff;
    display: block;
    position: absolute;
    left: 22px;
    top: 2px;
    transition: all 0.3s ease-in-out;
}

.tw-toggle input[value="false"]:checked~span {
    background: #e74c3c;
    left: 2px;
    color: #fff;
}

.tw-toggle input[value="true"]:checked~span {
    background: #27ae60;
    left: 46px;
}

.tw-toggle input[value="-1"]:checked~span {
    background: #95A5A6;
    left: 23px;
}

.tw-toggle input[value="false"]:checked+label,
.tw-toggle input[value="true"]:checked+label {
    color: #fff;
}

.tw-toggle input[value="-1"]:checked+label {
    color: #fff;
}

.adjustment-icon {
    position: absolute;
    right: 2%;
}

.border-label {
    position: relative;
}

.border-label-text {
    position: absolute;
    top: -10px; /* Adjust this value according to your preference */
    left: 10px; /* Adjust this value according to your preference */
    background-color: white; /* Ensure the label stands out from the background */
    padding: 0 5px; /* Adjust padding as needed */
    font-size: 14px; /* Adjust font size as needed */
}
