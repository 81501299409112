.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-field .MuiInputBase-root {
  height: 36px;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  color: "black"
}

.text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 0.1px solid #d6d6d6;
}

.text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da;
}

.text-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #d6d6d6;
}

.error-border {
  border-color: red;
}

.error-border .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.loginbtn {
  background-color: #E75605;
  height: 35px;
  border: none;
  border-style: none;
  color: white;
  border-radius: 4px;
}

.cancel-button {
  background-color: #F2F2F2;
  color: #E75605;
  height: 35px;
  border: none;
  border-style: none;
  border-radius: 4px;
  font-weight: 600;
}

.user-cancel-button {
  width: 130px;
  height: 37px;
  border-radius: 5px;
  border: 1px;
  padding: 1px 11px 1px 11px;
  color: #E75605;
  font-weight: 600;
}

.background-grid {
  background-color: #F1F1F1;
}

.header-text-grid {
  color: #006a9c;
}

.login_container {
  height: 540px;
}

.login-app {
  background-color: #fff;
  min-height: 592px;
}

.forgot-app {
  background-color: #fff;
  min-height: 592px;
}

.register-app {
  background-color: #fff;
  min-height: 710px;
}

.reset-app {
  background-color: #fff;
  height: 480px;
}

.terms_style {
  color: #0c66e4;
  cursor: pointer;
}

.card-margin {
  margin-top: 25px;
}

.card-style {
  width: 540px;
}

.card-style {
  margin-top: 40px;
  overflow: hidden;
}

.forgot-card-style {
  overflow: hidden;
  margin-top: 55px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.profile {
  max-width: 100%;
  height: 100%;
}

.profile-forgot {
  max-width: 100%;
  height: 135px;
  margin-left: 50px;
}

.addaddressbtn {
  background-color: #F1F1F1;
  height: 35px;
  border: none;
  border-style: none;
  color: #E75605;
  border-radius: 4px;
  /* float: right; */
  text-align: center;
}

.weekly-count {
  font-size: 15px;
  font-weight: 600;
  background-color: white;
}


.weekly-count i {
  margin: 0px 20px;
  cursor: pointer;
  background: #e75606;
  color: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 2px;
}

.weekly-count .disabled-i {
  margin: 0px 20px;
  cursor: not-allowed;
  background: #ccc5c1 !important;
  color: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 2px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-err .dropzone {
  border: 2px dashed #f46a6a !important;
}

.custom-dropzone .dropzone {
  min-height: 70px !important;
  border: 2px dashed var(--bs-border-color);
  border-radius: 6px;
}

.custom-dz-message {
  font-size: 20px;
  text-align: center;
  padding: 0px !important;
}

.forgot_pass_style {
  float: right;
  margin-top: -27px;
}


/* @media Screen and (min-width:320px) {
    .forgot-app {
    width: 550px;
    height: 750px;
  }

  .forgot-card-style {
    margin-top: 180px;
  }
}


@media Screen and (max-width:375px) {
  .forgot-app {
    width: 550px;
    height: 592px;
  }

  .profile {
    max-width: 100%;
    height: 134px;
  }

  .profile-login {
    max-width: 100%;
    height: 150px;
  }

  .forgot-card-style {
    margin-top: 170px;
  }
} */

@media screen and (min-width: 425px) and (max-width: 425px) {
  .forgot-app {
    width: 550px;
    height: 660px;
  }

  .forgot-card-style {
    margin-top: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  .forgot-app {
    width: 768px;
    height: 675px;
  }

  .forgot-card-style {
    margin-top: 65px;
  }

  .register-app {
    background-color: #fff;
    height: 938px;
  }
}

/* 
@media screen and (min-width: 1024px) {
  .forgot-app {
    width: 1024px;
    height: 703px;
  }

  .forgot-card-style {
    margin-top: 65px;
  }
} */


@media Screen and (max-width:1440px) {
  .profile-login {
    max-width: 100%;
    height: 120px;
  }

  /* .forgot-card-style {
    margin-top: 170px;
  } */
}

.custom-dz-message i,
.custom-dz-message h6 {
  margin: 0;
}

.custom-image-dropzone .dropzone {
  min-height: 75px !important;
  min-width: 75px !important;
  height: 75px !important;
  width: 75px !important;
  border: 2px dashed #6e7173 !important;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.custom-profile-dropzone .dropzone {
  min-height: 100px !important;
  min-width: 100px !important;
  height: 100px !important;
  width: 100px !important;
  border: 2px dashed #6e7173 !important;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  /* border: 1px solid #eaeaea; */
  /* margin-bottom: 8;
  margin-right: 8; */
  height: 75px !important;
  width: 75px !important;
  /* padding: 4; */
  box-sizing: border-box;
  position: relative;
}

.thumb-profile {
  display: inline-flex;
  border-radius: 50px;
  /* border: 1px solid #eaeaea; */
  /* margin-bottom: 8;
  margin-right: 8; */
  height: 100px !important;
  width: 100px !important;
  /* padding: 4; */
  box-sizing: border-box;
  position: relative;
  margin-top: 15px;
}

.circular-profile-image {
  border-radius: 50px;
  width: 100px;
  height: 100px;
}

.image-preview {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.image-preview-container {
  margin-top: 20px;
  max-width: 100%;
  overflow: hidden;
}

.img {
  height: 75px !important;
  width: 75px !important;
  display: block;
}

/* .img-adjustment {
  /* margin-top: -60px; 
} */

.thumbInner {
  display: 'flex';
  min-width: 0;
  overflow: 'hidden'
}

.selected-image-container {
  position: relative;
}

.delete-icon {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  box-sizing: border-box;
  width: 100px;
  height: 75px;
}

.row-adjustment {
  margin-top: -50px;
}

.inactive-badge {
  white-space: nowrap;
  position: absolute;
  top: -20px;
  right: 0px;
  background: #eec8c8;
  color: #d72d2d;
  border-radius: 4px;
  padding: 2px;
  font-size: 10px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .image-wrapper {
    flex-basis: 50%;
  }

}

@media (max-width: 576px) {
  .image-wrapper {
    flex-basis: 100%;
  }
}

.logo {
  width: 70px;
}

.custom-nav-lg {
  display: flex;
}

.center a {
  margin-right: 40px;
  font-weight: 600;
}

.right img {
  margin-right: 20px;
  height: 25px;
  cursor: pointer;
  margin-top: 5px;
}

.custom-button {
  color: #FFF !important;
  background: #E75605 !important;
}

.custom-button:hover {
  color: #E75605 !important;
  background: #FFF !important;
}

.custom-nav-sm {
  display: none !important;
}

@media (max-width: 576px) {
  .custom-nav-lg {
    display: none !important;
  }

  .custom-nav-sm {
    display: block !important;
    background-color: white;
    border-bottom: 0.5px solid #eee8e8;
  }
}

.custom-image-container {
  margin-top: -60px;
}

.nav-item a {
  color: #495058;
}

.nav-item a.active {
  color: #e75504;
}

.scanner-card {
  position: absolute !important;
  right: 10vw;
  transform: translate(-100%, 0%);
  padding: 15px;
  z-index: 999;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px !important;
}

.scanner-alert {
  position: absolute !important;
  right: 10vw;
  transform: translate(-100%, 0%);
  padding: 15px;
  z-index: 999;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px !important;
}

@media (max-width: 576px) {
  .scanner-card {
    transform: translate(0%, 0%);
  }

  .scanner-alert {
    transform: translate(-10%, 25%) !important;
  }
}

.notification-container {
  cursor: default;
  position: absolute;
  z-index: 999;
  top: 0;
  right: -2rem;
  width: 400px;
  font-weight: 300;
  background: white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2);
  animation-name: dropPanel;
  animation-iteration-count: 1;
  animation-timing-function: all;
  animation-duration: 0.75s;
}

.notification-container h3 {
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 700;
  color: #84929f;
  padding: 0.8rem 2rem;
}

.notification-container em {
  margin-right: 0.75rem;
  font-weight: 700;
  font-size: 115%;
  color: #b5c4d2;
  vertical-align: bottom;
  display: inline-block;
}

@keyframes dropPanel {
  0% {
    opacity: 0;
    transform: translateY(-100px) scaleY(0.5);
  }
}

.notification {
  box-sizing: border-box;
}

.notification.new {
  background: #f3f9fd;
}

.notification-container input.checkbox[type=checkbox] {
  display: none;
}

.notification-container input.checkbox[type=checkbox]+label {
  display: block;
}

.notification-container input.checkbox[type=checkbox]:not(:checked)+label {
  transition: height 0.25s;
  height: 0;
  padding: 0;
  font-size: 0;
  border: none;
}

.notification-container input.checkbox[type=checkbox]:not(:checked)+label * {
  display: none;
}

.notification-container input.checkbox[type=checkbox]:checked+label {
  height: 3.25rem;
  padding: 1.125rem 4rem 0.75rem 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.bell-count {
  position: absolute;
  background: #e75605;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  padding: 1px;
  right: 3vw;
  top: 1vw;
  color: white;
}

/* 
.ticket-modal {
  width: 1200px !important;
  transform: translate(-50%, 0px) !important
}

.ticket-modal .modal-content {
  width: 1200px !important;
} */

.excel-icon {
  color: #008000;
  font-size: 20px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  box-shadow: 0 2px 4px #00000045;
}

.export-label {
  color: #008000;
  font-size: 15px;
  font-family: math;
  font-weight: 600;
  padding-left: 5px;
}

.excel-col {
  margin-bottom: -23px;
}

.mobile-button {
  display: none !important;
}

.default-height {
  min-height: 500px;
}

.header-button {
  background: transparent !important;
  color: #E75605 !important;
  border: none !important;
}

@media screen and (max-width:1440px) {

  .default-height {
    min-height: 300px;
  }

  .bell-count {
    right: 3vw !important;
    top: 0.5vw !important;
  }
}

@media screen and (max-width:2560px) {
  .bell-count {
    right: 1.5vw;
    top: 0.5vw;
  }
}

@media screen and (max-width: 768px) {
  .mobile-button {
    display: inline-block !important;
  }

  .bell-count {
    right: 5vw !important;
    top: 1vw !important;
  }
}

@media screen and (max-width: 576px) {
  .mobile-button {
    display: inline-block !important;
  }

  .bell-count {
    right: 5vw !important;
    top: 2vw !important;
  }
}

@media screen and (max-width: 320px) {
  .mobile-button {
    display: inline-block !important;
  }

  .bell-count {
    right: 28.5vw !important;
    top: 2vw !important;
  }
}


@media screen and (min-width:321px) and (max-width: 1024px) {
  .mobile-button {
    display: inline-block !important;
  }

  .bell-count {
    right: 4vw;
    top: 1vw;
  }
}

@media screen and (min-width : 420px) and (max-width: 475px) {

  .loginbtn {
    margin-top: -20px;
  }

  .excel-col {
    margin-bottom: 10px;
  }

  .addaddressbtn {
    margin-top: 25px;
  }

  .bell-count {
    right: 21.5vw !important;
    top: 2vw !important;
  }

  .reset-app {
    height: 370px;
    margin-top: 20px;
  }

  .default-height {
    min-height: 400px;
  }
}

@media screen and (min-width:325px) and (max-width: 380px) {

  .bell-count {
    right: 24.5vw !important;
    top: 2vw !important;
  }
}


.registration-custom-checkbox input {
  border: 1px solid;
}

.order-count {
  color: #E75605;
  font-size: 15px;
  font-weight: bold;
}

.custom-multi-checkbox {
  margin-top: '5px';
  border: 1px solid;
}

.profile-icon-header {
  --bs-btn-hover-bg: #E75605 !important;
  border-radius: 100% !important;
  width: 35px;
  display: block;
  height: 35px;
  color: white !important;
  font-size: 15px;
  text-transform: capitalize;
  padding: 5px;
  margin-right: 10px;
  margin-top: 13px;
}

.profile-icon-header-admin {
  --bs-btn-hover-bg: #E75605 !important;
  border-radius: 100% !important;
  width: 35px;
  display: block;
  height: 35px;
  color: white !important;
  font-size: 15px;
  text-transform: capitalize;
  padding: 5px;
  margin-right: 2px;
  margin-top: 15px;
}

#menuToggle {
  display: block;
  position: relative;
  top: 20px;
  left: 20px;
  z-index: 10;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 300px;
  height: 60rem;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background: white;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  box-shadow: rgba(0, 0, 0, 0.24) 0px -8px 8px;
}

#menu li {
  padding: 10px 0;
  font-size: 15px;
  font-weight: 600;
}

#menuToggle input:checked~ul {
  transform: none;
}

.active-badge {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  background-color: #c2f7d6 !important;
  color: #12b50f;
}

.inactive-span {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  background-color: #eec8c8 !important;
  color: #d72d2d;
}

.nowrap {
  white-space: nowrap !important;
}

.page-name {
  font-size: 15px;
  font-weight: 600;
  color: #e75606;
}

.line-or {
  z-index: 2;
  position: relative;
  background: white;
}

.line-or-line {
  position: absolute;
  margin-top: -10px;
  border-top: 1px solid #d1caca;
  width: 80%;
  display: block;
  left: 10%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .notification-container {
    width: 250px;
    right: 0;
    top: 0;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    margin-right: -75px;
  }

  .notification-container h3 {
    text-align: center;
    margin: 0;
    padding: 0 1rem;
  }

  .notification-container em {
    margin-right: 0.5rem;
    font-weight: 700;
    font-size: 100%;
    color: #b5c4d2;
    vertical-align: bottom;
    display: inline-block;
  }

}

.custom-header-button {
  background-color: white !important;
}


.bold-border {
  border: 1.5px solid #e75606 !important;
}

.avatar-icon{
    align-items: center;
    color: white;
    display: flex;
    font-weight: medium;
    height: 100%;
    justify-content: center;
    width: 100%;
}